@font-face {
    font-family: sf-ui-display-bold;
    src: url('../fonts/sf-ui-display-bold.otf');
}
@font-face {
    font-family: sf-ui-display-medium;
    src: url('../fonts/sf-ui-display-medium.otf');
}
@font-face {
    font-family: sf-ui-display-semibold;
    src: url('../fonts/sf-ui-display-semibold.otf');
}
@font-face {
    font-family: sf-ui-display-thin;
    src: url('../fonts/sf-ui-display-thin.otf');
}
@font-face {
    font-family: sf-ui-display-light;
    src: url('../fonts/sf-ui-display-light.otf');
}