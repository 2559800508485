@font-face{
    font-family: Lato-Black;
    src: url('../fonts/Lato-Black.ttf');
}
@font-face{
    font-family: Lato-Light;
    src: url('../fonts/Lato-Light.ttf');
}


/* DM Fonts */
@font-face{
    font-family: DMSans-Bold;
    src: url('../fonts/DMSans-Bold.ttf');
}
@font-face{
    font-family: DMSans-Medium;
    src: url('../fonts/DMSans-Medium.ttf');
}
@font-face{
    font-family: DMSans-Regular;
    src: url('../fonts/DMSans-Regular.ttf');
}

/* Roboto-Regular fonts */
@font-face{
    font-family: Roboto-Regular;
    src: url('../fonts/Roboto-Regular.ttf');
}
@font-face{
    font-family: Roboto-Bold;
    src: url('../fonts/Roboto-Bold.ttf');
}
@font-face{
    font-family: Roboto-Medium;
    src: url('../fonts/Roboto-Medium.ttf');
}


/* Manrope fonts */
@font-face{
    font-family: Manrope-Bold;
    src: url('../fonts/Manrope-Bold.ttf');
}
@font-face{
    font-family: Manrope-SemiBold;
    src: url('../fonts/Manrope-SemiBold.ttf');
}
@font-face{
    font-family: Manrope-ExtraBold;
    src: url('../fonts/Manrope-ExtraBold.ttf');
}
@font-face{
    font-family: Manrope-Regular;
    src: url('../fonts/Manrope-Regular.ttf');
}
@font-face{
    font-family: Manrope-Light;
    src: url('../fonts/Manrope-Light.ttf');
}
@font-face{
    font-family: Manrope-ExtraLight;
    src: url('../fonts/Manrope-ExtraLight.ttf');
}