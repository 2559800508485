@import url("fonts.css");

body {
  background: #f5f5f5;
}
a {
  text-decoration: none;
}

.banner-img img {
  width: 100%;
}

.menu-toggle-btn {
  background: url("../images/menu.png") no-repeat center;
  /* width: 20px;
    height: 20px;
    border: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center; */
}
.menu-toggle-btn.close-toggle {
  background: url("../images/close.png") no-repeat center;
}
.head-wrap1 {
  box-sizing: border-box;
  /* position: absolute; */
  position: relative;
  z-index: 999;
  width: 100%;
  top: 0px;
  left: 0px;
  padding: 5px 20px;
  height: 40px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #d9d9d9;
}

.team-wrap {
  padding: 55px 0px 30px;
}

.t-slide-card .s-link img {
  width: auto;
}

.cus-container {
  padding: 0px 20px;
}

.s-link {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0px 25px;
}

.s-link a {
  margin: 0px 7px;
}

.t-content {
  padding: 0px 10px;
  text-align: center;
}

.t-content h4 {
  font-size: 20px;
  color: #000000;
  font-family: sf-ui-display-bold;
}

.t-content p {
  font-size: 14px;
  color: #7e8287;
  font-family: sf-ui-display-light;
  line-height: 17px;
}

.h-btn button {
  margin-bottom: 30px;
}

.blue-btn-new {
  width: 100%;
  height: 46px;
  font-size: 16px;
  color: #ffffff;
  font-family: sf-ui-display-semibold;
  background: #1b49ba;
  border-radius: 2px;
  border: 0px;
}

.btm-section {
  padding: 0px 15px;
}

.btm-w-block {
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 20px;
}

.btm-w-block img {
  width: 100%;
  height: auto;
}

.sidebar-wrap-new {
  width: 260px;
  height: 100vh;
  overflow: auto;
  background-color: #ffffff;
  position: fixed;
  top: 0px;
  left: -100%;
  z-index: 99;
  transition: all 0.3s linear 0s;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
}

.sidebar-wrap-new.shownav {
  transition: all 0.3s linear 0s;
  left: 0%;
}
.top-nav {
  padding: 0px 15px 0px 20px;
  overflow: auto;
  flex: 1;
}
.top-nav ul {
  padding: 0px;
  margin: 0px;
}
.top-nav ul li {
  list-style-type: none;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  padding: 15px 0px;
}
.top-nav ul li button {
  font-size: 16px;
  color: #777777;
  font-family: sf-ui-display-semibold;
}
.hide-scroll {
  overflow: hidden;
}
.side-nav-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0px;
}
.side-nav-footer a {
  font-size: 12px;
  color: #777777;
  font-family: sf-ui-display-semibold;
  padding: 0px 10px;
  border-left: 1px solid #777777;
}
.side-nav-footer a:first-child {
  border-left: 0px;
}
.trans-btn {
  background: transparent;
  border: 0px;
  padding: 0px;
}
.login-btn button,
.menu-block h5 {
  font-size: 16px;
  color: #777777;
  font-family: sf-ui-display-semibold;
}
.menu-block h5 {
  margin: 0px 0px 0px 10px;
}
.modal-dialog {
  /* margin: 0px; */
}
.modal-content {
  border: 0px;
  border-radius: 10px;
}
.b-modal-body {
  padding: 6px !important;
}
.bm-title {
  padding: 40px 20px 10px;
  position: relative;
}
.bm-title h4 {
  font-size: 22px;
  color: #000000;
  font-family: sf-ui-display-semibold;
  margin-bottom: 0px;
  text-align: center;
}
.btn-close {
  background: none !important;
  opacity: 1;
  position: absolute;
  top: 5px;
  right: 10px;
  width: auto;
  height: auto;
}
.b-i-head {
  background: #1b49ba;
  border-radius: 7px 7px 0px 0px;
  padding: 10px;
}
.b-i-head h4 {
  font-size: 21px;
  color: #ffffff;
  font-family: sf-ui-display-semibold;
}
.b-i-head h5 {
  font-size: 18px;
  color: #ffffff;
  font-family: sf-ui-display-semibold;
}
.b-c-foot {
  padding: 10px;
  background: #deebff;
  border-radius: 0px 0px 7px 7px;
}
.b-c-foot div {
  font-size: 18px;
  font-family: sf-ui-display-semibold;
  color: #000000;
  text-align: center;
}
.blue-info-card {
  margin-bottom: 20px;
}
.modal-dialog {
  width: 100%;
  max-width: 100%;
  /* height: 100%; */
}

.owl-stage-outer {
  z-index: 0 !important;
}

.owl-nav {
  z-index: 2;
  position: relative;
  top: -300px;
  display: flex !important;
  justify-content: space-between;
}

.items {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 60px;
  display: grid;
  border-radius: 28px;
  visibility: hidden;
  opacity: 0;
  background: rgb(0 0 0 / 15%);
  transition: 0.3s;
}

.items.open {
  visibility: visible;
  opacity: 1;
}

.items button {
  padding: 20px;
  white-space: nowrap;
}

.search.open div {
  max-width: 180px;
}

.search div {
  max-width: 0;
  padding: 5px 0 0 20px;
  /* transition: 0.4s; */
}

.search button {
  display: grid;
  place-items: center;
  /* width: 50px; */
  height: 50px;
  /* border-radius: 50%; */
  font-size: 24px;
  transition: 0.3s;
  border: 0;
  background: transparent;
}

.wrapper {
  position: absolute;
  text-align: center;
  z-index: 10;
  top: 0;
  right: 0;
}
.t-color {
  background: rgba(255, 255, 255, 0.75);
  border-radius: 37px;
  /* width: 129px; */
  padding-right: 20px
}
.search {
  position: relative;
  z-index: 1;
  display: inline-flex;
  padding: 5px;
  border-radius: 50px;
  transition: 0.4s;
}

.search:is(:hover, .open) {
  background: rgb(0 0 0 / 0.6%);
}

.menuDiv.open {
  visibility: visible;
}
.menuDiv {
  visibility: hidden;
}

/* .owl-carousel .owl-wrapper {
    display: flex !important;
}

.owl-carousel .owl-item .responsive{
    width: 100%;
    height: auto;
    object-fit: cover;
    max-width: initial;
} */

/* .owl-item {
    height: 0;
} */

/* .owl-item.active{
    height: auto;
} */

/* .modal.fade .modal-dialog {
  transform: translate(0, 20%) !important;
} */

.modal-dialog-end {
  display: flex;
  align-items: end !important;
  /* height: -webkit-fill-available; */
}

.cirle-icon {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 7%;
  z-index: 1;
}

.cirle {
  width: 70px;
  height: 70px;
  background: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  border-radius: 40px;
  /* position: relative; */
  /* top: -20%; */
}

.jDqeQC {
  -webkit-box-align: center;
  align-items: center;
  /* height: 4rem; */
  -webkit-box-pack: center;
  justify-content: center;
  margin: 0.5rem;
  position: relative;
  text-align: right;
  z-index: 111;
}
.iyheF {
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-radius: 100%;
  display: flex;
  font-size: 60px;
  height: 97px;
  -webkit-box-pack: center;
  justify-content: center;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 0px;
  transform: translate3d(-50%, -60%, 0px);
  width: 97px;
}

.mdc-elevation--z6 {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
}
.modal-icon {
  overflow: visible !important;
}

.ifEhrY {
  border: none;
  font-family: Inter, sans-serif;
  font-size: 0.75rem;
  height: 1rem;
  line-height: 1rem;
  position: relative;
  width: 100%;
}

.ifEhrY::before {
  background-color: rgb(230, 230, 230);
  content: "";
  height: 0.0625rem;
  left: 0px;
  position: absolute;
  top: 50%;
  width: 100%;
}

.ifEhrY::after {
  background-color: rgb(255, 255, 255);
  content: "OR";
  padding: 0px 1rem;
  position: relative;
}
.divider-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider {
  flex-grow: 1;
  border: none;
  border-top: 2px solid #000;
  margin: 0 10px;
}

.or-label {
  padding: 5px;
  background-color: #fff;
  font-weight: bold;
}

.or-tag {
  margin: 0 10px;
  font-weight: bold;
  color: #000;
}

@media only screen and (max-width: 600px) {
  .modal-margin-top-30 {
    margin-top: 35px !important;
  }
  .participant-page-contact-modal-content {
    top: 50px !important;
  }
  .modal-dialog-end {
    width: -webkit-fill-available !important;
  }
}

.line {
  display: flex;
}

.line::before,
.line::after {
  content: "";
  flex: 1 1;
  border-top: 1px solid #d3d2d3;
  margin: auto;
}

.text {
  margin: 0 1rem;
}
