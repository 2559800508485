.color-btn {
  background-color: #c70872;
  color: #fff;
  border-color: #c70872;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
}

#notfound {
  position: relative;
  height: 100vh;
}

#notfound .notfound-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/bg.jpg");
  background-size: cover;
}

#notfound .notfound-bg:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fab791;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 910px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
}

.notfound .notfound-404 {
  position: relative;
  height: 200px;
}

.notfound .notfound-404 h1 {
  font-family: "Montserrat", sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 220px;
  font-weight: 900;
  margin: 0px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 10px;
}

.notfound h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  margin-top: 20px;
  margin-bottom: 15px;
}

.notfound .home-btn,
.notfound .contact-btn {
  font-family: "Montserrat", sans-serif;
  display: inline-block;
  font-weight: 700;
  text-decoration: none;
  background-color: transparent;
  border: 2px solid transparent;
  text-transform: uppercase;
  padding: 13px 25px;
  font-size: 18px;
  border-radius: 40px;
  margin: 7px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.notfound .home-btn:hover,
.notfound .contact-btn:hover {
  opacity: 0.9;
}

.notfound .home-btn {
  color: #fab791;
  background: #fff;
}

.notfound .contact-btn {
  border: 2px solid rgba(255, 255, 255, 0.9);
  color: rgba(255, 255, 255, 0.9);
}

.notfound-social {
  margin-top: 25px;
}

.notfound-social > a {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  width: 40px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.9);
  margin: 0px 6px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}
.notfound-social > a:hover {
  color: #fab791;
  background-color: #fff;
  border-radius: 50%;
}

@media only screen and (max-width: 767px) {
  .notfound .notfound-404 h1 {
    font-size: 182px;
  }
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 {
    height: 146px;
  }
  .notfound .notfound-404 h1 {
    font-size: 146px;
  }
  .notfound h2 {
    font-size: 16px;
  }
  .notfound .home-btn,
  .notfound .contact-btn {
    font-size: 14px;
  }
}

/* .check-in-status1 {
  background: rgba(239, 209, 193, 0.15);
} */

/* .tapparazzi {
  border-style: solid;
  border-radius: 10px;
  border-width: 5px;
  border-color: #ebebeb; 
  max-width: 350px;
} */

.scroll-to-top {
  /* position: fixed; */
  /* width: 100%; */
  /* left: 80%; */
  bottom: 200px;
  height: 20px;
  font-size: 3rem;
  /* z-index: 1; */
  cursor: pointer;
  color: rgb(255, 255, 255);
  opacity: 0.8;
}
